import { useSession } from 'next-auth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Phase } from '@/components/common/Timeline/PhaseInformation';
import { Header } from '@/components/dynamic-nav/header';
import { getDynamicNavData } from '@/components/dynamic-nav/nav-config';
import { DynamicNavDataType, NavLinkItem, Role } from '@/components/dynamic-nav/types';
import { DYNAMIC_NAV_WIDTH, NAV_MINI_WIDTH } from '@/components/nav/nav-config';
import Scrollbar from '@/components/scrollbar';
import {
  GET_ACTIVE_DISCLOSURE_CYCLE_FOR_DISCLOSER,
  GET_EXISTING_PROJECT_REQUESTS_NUMBER,
  GET_LATE_REQUESTS_TOTAL_REQUESTS,
  GET_MY_DETAILS,
  GET_MY_ORG_DETAILS,
  GET_MY_REQUESTING_AUTHORITIES_TOTAL_REQUESTERS,
  GET_PHASES_INFORMATION,
} from '@/graphql/disclosure/queries';
import {
  GetActiveDisclosureCycleQuery,
  GetExistingProjectRequestsNumberQuery,
  GetLateRequestsTotalRequestsQuery,
  GetMyDetailsQuery,
  GetMyOrganisationDetailsQuery,
  GetPhasesInformationQuery,
  MyRequestsTotalRequestersQuery,
} from '@/lib/discloser/__generated__/graphql';
import { NavContextProvider, useNavContext } from '@/providers/NavProvider/navContextProvider';
import { useSettings } from '@/providers/SettingsProvider/SettingsProvider';
import { useStore } from '@/store/authorityType';
import { AuthorityTypeIdService } from '@/types/authority.type.id.service';
import { OrganisationTypeIdService } from '@/types/organisation.type.id.service';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { isImpersonating } from '@/utils/is.impersonating';
import { useQuery } from '@apollo/client';
import { applicationEnvironment, commonConfig } from '@config';
import { Box, Divider, Drawer, Stack } from '@mui/material';
import Loading from '../common/loading/Loading';
import { Footer } from './Footer';
import Logout from './Logout';
import { NavList } from './NavList';
import { ResetButton } from './ResetButton';

const Nav = () => {
  const { role } = useSettings();
  const { data: session } = useSession();
  const { closeNav, openNav, isOpen } = useNavContext();
  const { authorityTypeId } = useStore();
  const [
    configData,
    setConfigData,
  ] = useState<DynamicNavDataType>();

  const [
    currentUrl,
    setCurrentUrl,
  ] = useState<string | null>(null);

  const shouldSkipMyOrgDetails = session?.user.isCDPAdmin && !isImpersonating(session);
  const shouldSkipPhasesInformation =
    (session?.user.isCDPAdmin && !isImpersonating(session)) || (role === Role.AUTHORITY && !authorityTypeId);

  const {
    data: myOrgData,
    loading: orgDetailsLoading,
    error,
    refetch: refetchMyOrganisationDetailsQuery,
  } = useQuery<GetMyOrganisationDetailsQuery>(GET_MY_ORG_DETAILS, {
    client: disclosureClient,
    skip: shouldSkipMyOrgDetails,
    onError: onApolloError,
  });

  const { data: userDetails, loading: userDetailsLoading } = useQuery<GetMyDetailsQuery>(GET_MY_DETAILS, {
    fetchPolicy: 'network-only',
    client: disclosureClient,
    variables: {
      track: true,
    },
    onError: onApolloError,
  });

  const { data: phasesData, loading: phasesLoading } = useQuery<GetPhasesInformationQuery>(GET_PHASES_INFORMATION, {
    client: disclosureClient,
    variables: { authorityTypeId },
    fetchPolicy: 'network-only',
    skip: shouldSkipPhasesInformation,
    onError: onApolloError,
  });

  const organisationTypeId = myOrgData?.getMyOrganisationDetails?.organisationType?.organisationTypeId;

  const shouldSkipMyRequests =
    role !== Role.DISCLOSER || (session?.user.isCDPAdmin && !isImpersonating(session)) || !organisationTypeId;

  const {
    data,
    loading: requestCountLoading,
    refetch: refetchMyRequestsQuery,
  } = useQuery<MyRequestsTotalRequestersQuery>(GET_MY_REQUESTING_AUTHORITIES_TOTAL_REQUESTERS, {
    client: disclosureClient,
    skip: shouldSkipMyRequests,
    fetchPolicy: 'network-only',
    onError: onApolloError,
  });

  const { data: activeCycleData, loading: isLoadingActiveCycleData } = useQuery<GetActiveDisclosureCycleQuery>(
    GET_ACTIVE_DISCLOSURE_CYCLE_FOR_DISCLOSER,
    {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      onError: onApolloError,
      skip: shouldSkipMyRequests,
    },
  );

  const { data: lateRequestsData, loading: isLoadingLateRequestsData } = useQuery<GetLateRequestsTotalRequestsQuery>(
    GET_LATE_REQUESTS_TOTAL_REQUESTS,
    {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      variables: {
        disclosureCycleId: activeCycleData?.getActiveDisclosureCycle?.disclosureCycleId,
      },
      skip: shouldSkipMyRequests || !activeCycleData?.getActiveDisclosureCycle?.disclosureCycleId,
      onError: onApolloError,
    },
  );

  const { data: existingProjectsData, loading: isLoadingExistingProjectsData } =
    useQuery<GetExistingProjectRequestsNumberQuery>(GET_EXISTING_PROJECT_REQUESTS_NUMBER, {
      client: disclosureClient,
      onError: onApolloError,
      fetchPolicy: 'network-only',
      skip: shouldSkipMyRequests,
    });

  const isOrganisationTypeCompany = organisationTypeId === OrganisationTypeIdService.COMPANY();
  const requestCount = isOrganisationTypeCompany
    ? data?.myRequests.totalRequesters
    : existingProjectsData?.getExistingProjectRequests.totalRequests;
  const lateRequestsCount = lateRequestsData?.getLateRequests.totalRequests ?? 0;
  const totalRequestsCount = (requestCount ?? 0) + lateRequestsCount;

  // If the nav is open, use the full width, otherwise use the mini width
  const handleNavWidth = useMemo(() => {
    if (!isOpen) {
      return NAV_MINI_WIDTH;
    }

    return DYNAMIC_NAV_WIDTH;
  }, [
    isOpen,
  ]);

  const refetchData = useCallback(() => {
    refetchMyOrganisationDetailsQuery();
    refetchMyRequestsQuery();
  }, [
    refetchMyOrganisationDetailsQuery,
    refetchMyRequestsQuery,
  ]);

  const appendURLParamsForFeedback = useCallback(
    (url: string) => {
      const searchParams = [];

      const roles = session?.user?.roles ? session.user.roles.join(',') : role;
      searchParams.push(`user_type=${roles}`);

      if (userDetails?.getMyDetails?.givenName) searchParams.push(`first_name=${userDetails.getMyDetails?.givenName}`);
      if (userDetails?.getMyDetails?.surname) searchParams.push(`surname=${userDetails.getMyDetails?.surname}`);
      if (userDetails?.getMyDetails?.emailAddress)
        searchParams.push(`email=${userDetails.getMyDetails?.emailAddress as string}`);
      if (myOrgData?.getMyOrganisationDetails?.orgName)
        searchParams.push(`org=${myOrgData.getMyOrganisationDetails.orgName}`);
      if (myOrgData?.getMyOrganisationDetails?.organisationType)
        searchParams.push(`org_type=${myOrgData.getMyOrganisationDetails.organisationType.name}`);
      if (currentUrl) searchParams.push(`current_page=${currentUrl.split('#')[0]}`);

      return `${url}#${searchParams.join('&')}`;
    },
    [
      userDetails,
      role,
      myOrgData,
    ],
  );

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  useEffect(() => {
    const updatedConfigData = getDynamicNavData(role ?? Role.UNKNOWN);
    const orgTypeId = myOrgData?.getMyOrganisationDetails?.orgTypeId;
    const feedbackItem = updatedConfigData.footer.find(item => item.title === 'nav.footer.feedback');

    if (feedbackItem) {
      feedbackItem.path = appendURLParamsForFeedback(feedbackItem.path);
    }

    if (orgTypeId) {
      let reportingGuidanceLink = '';
      switch (orgTypeId) {
        case OrganisationTypeIdService.STATES_AND_REGIONS(): {
          reportingGuidanceLink = commonConfig.reportingGuidanceLinkForSubNationalGovernment;
          break;
        }
        case OrganisationTypeIdService.COMPANY(): {
          reportingGuidanceLink = commonConfig.reportingGuidanceLinkForCompany;
          break;
        }
        case OrganisationTypeIdService.CITY(): {
          reportingGuidanceLink = commonConfig.reportingGuidanceLinkForCity;
          break;
        }
      }

      updatedConfigData?.footer[0]?.subs?.forEach(sub => {
        if (sub.title === 'nav.footerResourcesSubs.reportingGuidance') {
          sub.path = reportingGuidanceLink;
        }
      });
    }

    if (authorityTypeId === AuthorityTypeIdService.CAPITAL_MARKETS_SIGNATORY()) {
      updatedConfigData.links = updatedConfigData.links?.filter(
        link => (link as NavLinkItem).title !== 'nav.listItems.manageOrgs',
      );
    }

    if ((phasesData?.getPhasesInformation?.phaseName as Phase) === 'request') {
      updatedConfigData.links = updatedConfigData.links?.filter(
        link => (link as NavLinkItem).title !== 'nav.listItems.myQuestionnaire',
      );
    }

    setConfigData(updatedConfigData);
  }, [
    authorityTypeId,
    role,
    myOrgData,
  ]);

  if (!role) {
    return null;
  }

  const feedbackItem = configData?.footer.find(item => item.title === 'nav.footer.feedback');

  if (feedbackItem) {
    feedbackItem.path = appendURLParamsForFeedback(feedbackItem.path);
  }

  const isProdEnvironment = applicationEnvironment.trim() === 'prd' || applicationEnvironment.trim() === 'prod';

  const navContent = (
    <Scrollbar sx={{ height: '100%' }}>
      <Stack
        sx={{
          px: 3,
          py: 3,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Header
          role={role}
          myOrgData={myOrgData}
          loading={
            orgDetailsLoading ||
            requestCountLoading ||
            phasesLoading ||
            userDetailsLoading ||
            isLoadingActiveCycleData ||
            isLoadingLateRequestsData
          }
          refetch={refetchData}
          error={error}
        />
        {isLoadingExistingProjectsData ||
        orgDetailsLoading ||
        userDetailsLoading ||
        phasesLoading ||
        isLoadingActiveCycleData ||
        isLoadingLateRequestsData ||
        requestCountLoading ? (
          <Loading />
        ) : (
          <NavList
            list={configData?.links ?? []}
            requestCount={totalRequestsCount}
          />
        )}
        <ResetButton isProd={isProdEnvironment} />
        <Footer list={configData?.footer ?? []} />
        <Divider />
        <Logout />
      </Stack>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: 0,
        width: handleNavWidth,
        transition: 'width .3s ease-in-out',
      }}
    >
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: {
            width: handleNavWidth,
            transition: 'width .3s ease-in-out',
            backgroundColor: theme => theme.palette.grey[50],
            borderRightStyle: 'dashed',
          },
        }}
        onMouseEnter={openNav}
        onMouseLeave={closeNav}
      >
        {navContent}
      </Drawer>
    </Box>
  );
};

export const DynamicNav = () => {
  return (
    <NavContextProvider>
      <Nav />
    </NavContextProvider>
  );
};
