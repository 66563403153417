import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImpersonationAlert } from '@/components/common/ImpersonationAlert/ImpersonationAlert';
import { Role } from '@/components/dynamic-nav/types';
import { useNavContext } from '@/providers/NavProvider/navContextProvider';
import { useSettings } from '@/providers/SettingsProvider/SettingsProvider';
import { useStore } from '@/store/authorityType';
import { selectMenuProps } from '@/utils/SelectTextFieldPropsUtil';
import { Routes } from '@/utils/urls';
import { GroupAddOutlined } from '@mui/icons-material';
import {
  Avatar,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';

interface HeaderViewProps {
  orgName: string;
  jobTitle: string;
  imageUrl?: string | null;
  memberships?: Membership[];
  authorityTypes?: AuthorityType[];
}

export type AuthorityType = {
  id: string;
  name: string;
};

export type Membership = {
  id: string;
  name: string;
};

export const HeaderView: FunctionComponent<HeaderViewProps> = ({
  orgName,
  jobTitle,
  imageUrl,
  memberships,
  authorityTypes,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.nav.header' });
  const { t: t2 } = useTranslation('dynamic', { keyPrefix: 'dynamic.navbar.orgtypeselector' });
  const { isOpen } = useNavContext();
  const { role, availableRoles, setRole } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const { authorityTypeId, setAuthorityTypeId } = useStore();
  const router = useRouter();

  const [
    membership,
    setMembership,
  ] = useState<string | undefined>(memberships?.[0]?.id);

  const handleSelect = (e: SelectChangeEvent<string>) => setMembership(e.target.value);
  const handleAuthorityTypeSelect = (e: SelectChangeEvent<string>) => setAuthorityTypeId(e.target.value);

  const theme = useTheme();

  if (role === Role.UNKNOWN && availableRoles.length > 0) {
    setRole(availableRoles[0].value);
  }

  useEffect(() => {
    if (authorityTypes && authorityTypeId.length === 0 && authorityTypes.length > 0) {
      setAuthorityTypeId(authorityTypes[0]?.id);
    }

    if (availableRoles.length === 1) {
      setRole(availableRoles[0].value);
    }
  }, []);

  return (
    <>
      <Stack
        width="100%"
        bgcolor={theme => alpha(theme.palette.secondary.main, 0.04)}
        p={2}
      >
        {isOpen && memberships && memberships.length > 1 && (
          <FormControl
            sx={{
              marginBottom: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Select
              value={membership ?? memberships?.[0]?.id}
              onChange={handleSelect}
              MenuProps={selectMenuProps}
            >
              {memberships.map(option => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {isOpen && (
          <FormControl
            sx={{
              marginBottom: 4,
              gap: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Select
              onMouseEnter={e => e.stopPropagation()}
              onMouseLeave={e => e.stopPropagation()}
              data-testid="authority-role-selector"
              size="small"
              value={role}
              onChange={e => {
                setRole(e.target.value as Role);
                router.push(Routes.Home);
                enqueueSnackbar(t('switchSuccess', { userRoleType: e.target.value }), { variant: 'success' });
              }}
              disabled={availableRoles.length <= 1}
              style={{ color: theme.palette.text.primary }}
              MenuProps={selectMenuProps}
            >
              {availableRoles.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {t2(option.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {isOpen && role !== Role.DISCLOSER && !!authorityTypes?.length && (
          <FormControl
            sx={{
              marginBottom: 4,
              gap: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Select
              onMouseEnter={e => e.stopPropagation()}
              onMouseLeave={e => e.stopPropagation()}
              data-testid="authority-selector"
              size="small"
              value={authorityTypeId}
              onChange={handleAuthorityTypeSelect}
              MenuProps={selectMenuProps}
            >
              {authorityTypes.map(option => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Stack
          spacing={2}
          direction="row"
        >
          <Avatar
            alt={orgName}
            src={imageUrl ?? ''}
            sx={{
              cursor: 'pointer',
              borderRadius: 0.5,
            }}
          />

          <Stack
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Typography
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {orgName}
            </Typography>
            <Typography
              variant="caption"
              overflow="hidden"
              textOverflow="ellipsis"
              lineHeight={1}
              color={theme => theme.palette.text.secondary}
            >
              {jobTitle}
            </Typography>
          </Stack>
        </Stack>
        {isOpen && (
          <Stack
            mt={1}
            alignSelf="flex-start"
          >
            <Button
              fullWidth
              startIcon={<GroupAddOutlined />}
              variant="text"
              color="secondary"
              LinkComponent={Link}
              href={Routes.Organisation.Users}
            >
              {t('inviteTeamMember')}
            </Button>
          </Stack>
        )}
      </Stack>
      <ImpersonationAlert sx={{ mt: 2 }} />
    </>
  );
};
