import { useTranslation } from 'next-i18next';
import { AzureB2C } from '@config';
import { LogoutOutlined } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import { NavListButton } from './NavListItem';

const SIGN_OUT_URL =
  `${AzureB2C.customDomain}/${AzureB2C.tenantName}.onmicrosoft.com/${AzureB2C.userFlow}/oauth2/v2.0/logout?` +
  new URLSearchParams({
    post_logout_redirect_uri: `${AzureB2C.nextAuth}/signout`,
  });

export default function Logout() {
  const { t } = useTranslation('common', { keyPrefix: 'common.account' });

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    localStorage.removeItem('selectedRole');
    window.location.href = SIGN_OUT_URL;
  };

  return (
    <Box my={2}>
      <NavListButton
        LinkComponent={Link}
        href={SIGN_OUT_URL}
        color="secondary"
        title={t('logOutCtaLabel')}
        icon={<LogoutOutlined />}
        onClick={handleLogout}
      />
    </Box>
  );
}
